import * as React from "react"
import { Script } from "gatsby"

const BlogPage = () => {
  return (
    <>
      <main>
        <div id="dib-posts"></div>
      </main>
      <Script src="https://io.dropinblog.com/embedjs/5e03db61-a107-4fa4-9337-2f0a4760a919.js"/>
    </>

  )
}

export default BlogPage

export const Head = () => <title>Blog</title>
